import React, {useEffect, useState} from "react";
import Link from "gatsby-link";
import menupl from "../../data/main-menu/pl";
import menuen from "../../data/main-menu/en";
import privacyen from "../../data/cookies-info/en";
import privacypl from "../../data/cookies-info/pl";
import "./sitemap.scss";

export default function Sitemap({langKey}) {
    const menu = {
        pl: menupl,
        en: menuen
    };
    const policy = {
        pl: privacypl,
        en: privacyen
    };
    const [mainMenu, setMenu] = useState(menu[langKey]);

    useEffect(() => {
        setMenu(menu[langKey]);
    }, [langKey, menu]);

    const generateMenu = () => {
        if (mainMenu) {
            const links = mainMenu.map(item => {
                    return (
                        <li key={item.name} className="main-menu__item">
                            <Link to={item.slug}>
                                {item.name}
                            </Link>
                        </li>
                    );
                }
            );
            return links;
        }
    };
    return (
        <div>
            <ul className="sitemap">
                {generateMenu()}
                <li key="policy" className="main-menu__item">
                    <Link to={`/privacy-policy`}>
                        {policy[langKey].link}
                    </Link>
                </li>
            </ul>
        </div>
    );
}