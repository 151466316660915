import React from "react";
import ReactHtmlParser from "react-html-parser";
import cookiespl from "../../data/cookies-info/pl";
import cookiesen from "../../data/cookies-info/en";
import "./styles.scss";

const acceptCookies = () => {
    document.getElementById("cookies-bar").remove();
    localStorage.setItem("cookies", "true");
};

export default function Cookies({langKey}) {
    const content = {
        pl: cookiespl,
        en: cookiesen
    };

    return (
        <div id="cookies-bar" className="cookies-bar">
            <div className="container">
                {ReactHtmlParser(content[langKey].text)}
                <button className="button agree" onClick={acceptCookies}>&times;</button>
            </div>
        </div>
    );
}
