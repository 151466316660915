import React, {Fragment, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import HeaderSection from "../sections/header";
import {getCurrentLangKey, getLangs, getUrlForLang} from "ptz-i18n";
import {StaticQuery, graphql} from "gatsby";
import {addLocaleData, IntlProvider} from "react-intl";
import EnglishFlag from "../data/flags/en";
import PolishFlag from "../data/flags/pl";
import Cookies from "../sections/Cookies";
import Footer from "../components/footer";
import "intl";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/CSS/fontello.css";
import "../assets/sass/_globals.scss";
import en from "react-intl/locale-data/en";
import pl from "react-intl/locale-data/pl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/pl";

library.add(faCheck);
library.add(faInfoCircle);

addLocaleData(en);
addLocaleData(pl);

export default function Layout({children, location}) {
    const [isClient, setIsClient] = useState(false);
    const [cookiesAcceptance, setCookiesAcceptance] = useState(false);
    const flag = {
        pl: <EnglishFlag/>,
        en: <PolishFlag/>
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            setIsClient(true);
        }
    }, [isClient]);

    useEffect(() => {
        isClient &&
        localStorage.getItem("cookies") ? setCookiesAcceptance(false) : setCookiesAcceptance(true);
    }, [isClient]);

    return (
        <StaticQuery query={graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    languages {
                        defaultLangKey
                        langs 
                    }      
                }
            }
        }
        `}
                     render={data => {
                         const url = location.pathname;
                         const {langs, defaultLangKey} = data.site.siteMetadata.languages;
                         const langKey = getCurrentLangKey(langs, defaultLangKey, url);
                         const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/");
                         ;
                         const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({
                             ...item,
                             link: item.link.replace(`/${defaultLangKey}/`, "/"),
                             flag: flag[langKey]
                         }));
                         return (
                             <IntlProvider
                                 locale={langKey}
                             >
                                 <Fragment>
                                     <HeaderSection langsMenu={langsMenu}
                                                    langKey={langKey}
                                                    isClient={isClient}
                                                    homeLink={homeLink}/>
                                     <main>
                                         {children}
                                     </main>
                                     <Footer langKey={langKey}/>
                                     {cookiesAcceptance &&
                                     <Cookies langKey={langKey}/>
                                     }
                                 </Fragment>
                             </IntlProvider>
                         );
                     }}/>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired
};

