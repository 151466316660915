module.exports = [
    {
        name: "Strona główna",
        slug: "/",
    },
    {
        name: "o nas",
        slug: "/about/",
    },
    {
        name: "oferta i realizacje",
        slug: "/offer/",
    },
    {
        name: "blog",
        slug: "/blog/"
    },
    {
        name: "kontakt",
        slug: "/contact-us/",
    },
]
