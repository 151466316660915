import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "../../components/image/image"

const PolishFlag = () => {
    const polishFlag = useStaticQuery(graphql`
    {
      imageSharp(fixed:{originalName:{eq:"polish-flag.jpg"}}) {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    `)
    return (
        <Image fluid={false}
               image={polishFlag.imageSharp.fixed}
               alt="flaga polski"
               fit="cover"
        />
    )
}

export default PolishFlag
