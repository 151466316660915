// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-pl-js": () => import("./../../../src/pages/404.pl.js" /* webpackChunkName: "component---src-pages-404-pl-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-pl-js": () => import("./../../../src/pages/about/index.pl.js" /* webpackChunkName: "component---src-pages-about-index-pl-js" */),
  "component---src-pages-articles-company-website-pl-js": () => import("./../../../src/pages/articles/company-website.pl.js" /* webpackChunkName: "component---src-pages-articles-company-website-pl-js" */),
  "component---src-pages-articles-create-website-pl-js": () => import("./../../../src/pages/articles/create-website.pl.js" /* webpackChunkName: "component---src-pages-articles-create-website-pl-js" */),
  "component---src-pages-articles-design-website-pl-js": () => import("./../../../src/pages/articles/design-website.pl.js" /* webpackChunkName: "component---src-pages-articles-design-website-pl-js" */),
  "component---src-pages-articles-history-pl-js": () => import("./../../../src/pages/articles/history.pl.js" /* webpackChunkName: "component---src-pages-articles-history-pl-js" */),
  "component---src-pages-articles-modern-website-pl-js": () => import("./../../../src/pages/articles/modern-website.pl.js" /* webpackChunkName: "component---src-pages-articles-modern-website-pl-js" */),
  "component---src-pages-articles-promote-website-pl-js": () => import("./../../../src/pages/articles/promote-website.pl.js" /* webpackChunkName: "component---src-pages-articles-promote-website-pl-js" */),
  "component---src-pages-blog-index-pl-js": () => import("./../../../src/pages/blog/index.pl.js" /* webpackChunkName: "component---src-pages-blog-index-pl-js" */),
  "component---src-pages-contact-us-index-en-js": () => import("./../../../src/pages/contact-us/index.en.js" /* webpackChunkName: "component---src-pages-contact-us-index-en-js" */),
  "component---src-pages-contact-us-index-pl-js": () => import("./../../../src/pages/contact-us/index.pl.js" /* webpackChunkName: "component---src-pages-contact-us-index-pl-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-offer-index-en-js": () => import("./../../../src/pages/offer/index.en.js" /* webpackChunkName: "component---src-pages-offer-index-en-js" */),
  "component---src-pages-offer-index-pl-js": () => import("./../../../src/pages/offer/index.pl.js" /* webpackChunkName: "component---src-pages-offer-index-pl-js" */),
  "component---src-pages-privacy-policy-index-en-js": () => import("./../../../src/pages/privacy-policy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-en-js" */),
  "component---src-pages-privacy-policy-index-pl-js": () => import("./../../../src/pages/privacy-policy/index.pl.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-pl-js" */)
}

