module.exports = [
    {
        name: "Homepage",
        slug: "/en/",
    },
    {
        name: "about us",
        slug: "/en/about/",
    },
    {
        name: "offer and realizations",
        slug: "/en/offer/",
    },
    {
        name: "contact",
        slug: "/en/contact-us/",
    },
]
