import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import {MobileView, isMobile, isTablet, isBrowser} from "react-device-detect";
import Link from "gatsby-link";
import SelectLanguage from "../select-language/select-language";
import menupl from "../../data/main-menu/pl";
import menuen from "../../data/main-menu/en";
import "./main-menu.scss";

export default function MainMenu({langsMenu, langKey, isClient, homeUrl}) {
    const menu = {
        pl: menupl,
        en: menuen
    };
    const [mainMenu, setMenu] = useState(menu[langKey]);
    const [activeMenu, setActiveMenu] = useState(false);
    const [scrolledTop, setScrolledTop] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        setMenu(menu[langKey]);
    }, [langKey, menu, homeUrl]);

    useEffect(() => {
        if (isClient) {
            document.addEventListener("mousedown", handleClickOutside);
        }
    });

    if (isClient) {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 30) {
                setScrolledTop(true);
            } else {
                setScrolledTop(false);
            }
        });
    }
    const handleClickOutside = (event) => {
        if (isClient && (isMobile || isTablet) && !activeMenu) {
            if (event.target.id !== "hamburger" && !menuRef.current.contains(event.target)) {
                setActiveMenu(false);
            }
        }
    };
    const generateMenu = () => {
        if (mainMenu) {
            const links = mainMenu.map(item => {
                    return (
                        <li key={item.name} className="main-menu__item">
                            <Link to={item.slug} activeClassName="active" onClick={() => setActiveMenu(false)}>
                                {item.name}
                            </Link>
                        </li>
                    );
                }
            );
            return links;
        }
    };
    return (
        <nav>
            <MobileView>
                <div className={`hamburger-container ${scrolledTop ? "fixed" : ""}`}>
                    <span id="hamburger"
                          className={`hamburger ${activeMenu ? "active" : ""}`}
                          onClick={() => setActiveMenu(!activeMenu)}/>
                </div>
            </MobileView>
            {isClient &&
            <ul className={
                `main-menu ${activeMenu ? "active" : ""} 
                ${scrolledTop ? "fixed" : ""}
                ${isBrowser ? "desktop" : ""} 
            `}
                ref={menuRef}
            >
                {generateMenu()}
                <li className="main-menu__item">
                    <SelectLanguage langs={langsMenu} homeUrl={homeUrl}/>
                </li>
            </ul>
            }
        </nav>
    );
}

MainMenu.propTypes = {
    langsMenu: PropTypes.array.isRequired
};
