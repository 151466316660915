import React, {Fragment, useState, useEffect} from "react";
import MapContainer from "../map/index";
import Carousel from "../carousel/carousel";
import {TestimonialsOptions} from "../carousel/configs";
import testimonialspl from "../../data/testimonials/pl";
import testimonialsen from "../../data/testimonials/en";
import "./footer.scss";
import Sitemap from "../sitemap";

export default function Footer(props) {
    const getTestimonials = {
        pl: testimonialspl,
        en: testimonialsen
    };
    const [testimonials, setTestimonial] = useState(getTestimonials[props.langKey]);

    useEffect(() => {
        setTestimonial(getTestimonials[props.langKey]);
    }, [props.langKey, getTestimonials]);

    const GenerateTestimonials = () => {
        if (testimonials) {
            return testimonials.map(testimonial => (
                <div>
                    <p className="testimonial-content">
                        {testimonial.testimonial}
                    </p>
                    <p className="testimonial-author">
                        {testimonial.name}
                    </p>
                </div>
            ));
        }
    };

    return (
        <footer>
            <div className="extras">
                <div className="container">
                    <div className="extras__content">
                        <div className="extras__content--box">
                            <MapContainer language={props.langKey} langKey={props.langKey}/>
                        </div>
                        <div className="extras__content--box">
                            <Carousel options={TestimonialsOptions} content={GenerateTestimonials()}/>
                        </div>
                        <div className="extras__content--box">
                            <div className="fb-page" data-href="https://www.facebook.com/batinweb/" data-tabs=""
                                 data-width="" data-height="" data-small-header="false"
                                 data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                                <blockquote cite="https://www.facebook.com/batinweb/" className="fb-xfbml-parse-ignore">
                                    <a href="https://www.facebook.com/batinweb/" target="_blank">Bat in Web</a>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <Sitemap langKey={props.langKey}/>
                </div>
            </div>
            <p className="copyrights">&copy;&nbsp;Copyrights Bat in Web Maciej Szałkowski All rights reserved.
                2018-{new Date().getFullYear()}</p>
        </footer>
    );
}
