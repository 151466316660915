import React, {useState} from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from "google-maps-react";
import icon from "../../images/marker.png";
import detailspl from "../../data/contact-details/pl";
import detailsen from "../../data/contact-details/en";
import "./map.scss";

export function MapContainer({langKey}) {
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const details = {
        pl: detailspl,
        en: detailsen
    };

    const contactDetails = <div>
        <p>{details[langKey].companyName}</p>
        <p>{details[langKey].address1}</p>
        <p>{details[langKey].address2}</p>
        <p>{details[langKey].address3}</p>
    </div>;

    const onMarkerClick = (props, marker) => {
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    };

    return (
        <Map google={window.google}
             zoom={6}
             initialCenter={{
                 lat: 53.1097806,
                 lng: 23.14054027
             }}
             zoomControl={true}
             fullscreenControl={true}
             mapTypeControl={false}
             streetViewControl={false}
        >
            <Marker position={{
                lat: 53.146779,
                lng: 23.106280
            }}
                    icon={icon}
                    onClick={onMarkerClick}
            />
            <InfoWindow
                marker={activeMarker}
                visible={showingInfoWindow}>
                {contactDetails}
            </InfoWindow>
        </Map>
    );
}

export default GoogleApiWrapper((props) => ({
    apiKey: process.env.GATSBY_MAPS_API,
    language: props.language
}))(MapContainer);
