import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import PropTypes from "prop-types"


export default function Image({fluid, image, alt, fit, classname }) {
    return (
        fluid ? <Img fluid={image}
                     alt={alt}
                     objectFit={fit}
                     objectPosition="50% 50%"
                     className={`image ${classname}`}
            />
            : <Img fixed={image}
                   alt={alt}
                   objectFit={fit}
                   objectPosition="50% 50%"
                   className={`image ${classname}`}
            />

    )
}

Image.propTypes = {
    image: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
    fit: PropTypes.string,
    classname: PropTypes.string,
    fluid: PropTypes.bool,
}

Image.defaultProps = {
    fit: "contain",
    classname: "",
    fluid: true,
}
