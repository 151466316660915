import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import Image from "../image/image";
import HorizontalLine from "../horizontal-line";
import "./header.scss";

const Header = ({link}) => {
    const logo = useStaticQuery(graphql`
        {
            imageSharp(fixed: {originalName: {eq: "logo.png"}}) {
                fixed(width: 140) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }
    `);
    return (
        <div className="header">
            <Link to={link} className="logo">
                <Image fluid={false}
                       image={logo.imageSharp.fixed}
                       alt="logo"
                />
            </Link>
            <HorizontalLine classname="wide blue"/>
        </div>
    );
};

export default Header;
