import React from "react";
import HorizontalLine from "../components/horizontal-line";
import MainMenu from "../components/main-menu";
import Header from "../components/header";

export default function HeaderSection({langsMenu, langKey, isClient, homeLink}) {
    return (
        <header>
            <HorizontalLine classname="wide black"/>
            <div className="container">
                <Header link={homeLink}/>
                <MainMenu langsMenu={langsMenu} langKey={langKey} isClient={isClient}/>
            </div>
            <HorizontalLine classname="wide black"/>
        </header>
    );
}
