module.exports = [
    {
        name: "Wysocki fizjoterapia",
        testimonial: "Bat in Web zrealizowało moją wizję strony internetowej i jestem bardzo zadowolony z uzyskanych wspólnie efektów, współpraca przebiegała bez zarzutów, a oferta cenowa bardzo konkurencyjna. Kompleksowo, szybko, solidnie.",
    },
    {
        name: "MJZ Studio",
        testimonial: "Współpraca z Bat in Web przebiegła bardzo sprawnie. Wszystkie rzeczy które chcieliśmy zostały wykonane, nie było rzeczy których nie da się zrobić. Bardzo dobra organizacja pracy. Obsługa serwisu po utworzeniu domeny www również na wysokim poziomie, wiemy że nasza strona jest bezpieczna, posiada zawsze aktualny backup. Zostajemy w stałej współpracy i pewno wrócimy po kolejne projekty.",
    },
    {
        name: "Solar Technics Sp. z o. o. - Sp. k.",
        testimonial: "Polecamy usługi wykonywane przez firmę Bat in Web, dotyczące modernizacji stron internetowych. Jakość wykonywanych prac jest wysoka. Komunikacja w trakcie współpracy była bardzo dobra.",
    },
    {
        name: "Jarosław Sołtan",
        testimonial: "Serdecznie polecam firmę Bat in Web. Jej właściciel, Maciej Szałkowski reprezentuje wszystkie najlepsze cechy osoby mającej talent do pracy z klientem. Jego profesjonalne podejście do rozwiązywania problemów i pasja do ciągłych ulepszeń, sprawiła że współpraca z nim była prawdziwie twórcza i przyjemna. Jego cierpliwość pozwoliła mi stworzyć dokładnie to, co chciałem - stronę estetycznie elegancką i technicznie wyrafinowaną, nowoczesną i zgodną z najnowszymi trendami stron www.",
    },
]
