import React, {useEffect, useState} from "react";
import Swiper from "react-id-swiper";
import 'swiper/css/swiper.css';
import "./carousel.scss";

export default function Carousel({options, content}) {
    const [contentState, setContent] = useState(content);

    useEffect(() => {
        setContent(content);
    }, [content]);

    return (
        <Swiper {...options} rebuildOnUpdate>
            {contentState}
        </Swiper>
    );
}
