export const OfferOptions = {
    containerModifierClass: "offer-",
    direction: "horizontal",
    autoplay: {
        delay: 5000
    },
    navigation: {
        nextEl: ".navigation.next-btn.icon-right-open-big",
        prevEl: ".navigation.prev-btn.icon-left-open-big"
    },
    speed: 1000,
    spaceBetween: 80,
    centeredSlides: true,
    loop: true,
    grabCursor: true,
    slidesPerView: 1,
    noSwipingSelector: ".navigation",
    pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
    },
    breakpoints: {
        768: {
            slidesPerView: 2
        },
        1366: {
            slidesPerView: 3
        }
    }
};

export const TestimonialsOptions = {
    slidesPerView: 1,
    containerModifierClass: "testimonials-",
    pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
    },
    speed: 1000,
    loop: true,
    autoplay: {
        delay: 15000
    }
};
