module.exports = [
    {
        name: "Wysocki fizjoterapia",
        testimonial: "Bat in Web realized my vision of the website and I am very pleased with the results obtained together, the cooperation proceeded smoothly, and the price offer was very competitive. Compressively, quickly, reliably.",
    },
    {
        name: "MJZ Studio",
        testimonial: "Cooperation with Bat in Web went very smoothly. All the things we wanted have been done. Very good work organization. Support for the website after the creation of the www domain also at a high level, we know that our site is safe, always has up-to-date backup. We stay in constant cooperation and we will definitely come back for more projects.",
    },
    {
        name: "Solar Technics Sp. z o. o. - Sp. k.",
        testimonial: "We recommend services provided by Bat in Web regarding the modernization of websites. The quality of work is high. communication during the cooperation was very good.",
    },
    {
        name: "Yaroslaff Soltan",
        testimonial: "I deeply recommend Bat in Web. Its owner, Maciej Szalkowski, has a true talent for working with clients. His professional approach to problem solving and passion for continuous improvement made cooperation with him truly creative and enjoyable. His patience allowed me to achieve exactly what I needed - an aesthetically elegant and technically sophisticated website, modern and in line with the latest website trends.",
    },
]

