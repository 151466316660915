import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";

const SelectLanguage = ({langs}) => {
    const links = langs.map(lang =>
        <Link to={lang.link}
              key={lang.langKey}
              style={!lang.selected ? {display: "block"} : {display: "none"}}>
            <li selected={lang.selected} style={{lineHeight: 1}}>
                {lang.flag}
            </li>
        </Link>
    );

    return (
        <ul>
            {links}
        </ul>
    );
};

SelectLanguage.propTypes = {
    langs: PropTypes.array.isRequired
};

export default SelectLanguage;
